import React from 'react'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {Link} from 'react-scroll'
// import About from '../components/about/About';

const NavbarComponent = () => {
  return (
    <>
     <Navbar collapseOnSelect expand="lg" className="navbar-light bg-white border-bottom"  sticky="top">
      <div className="container">
        <Navbar.Brand className="navbar-brand lgo" href="/">
          <img src="assets/sT-new-logo.svg" className="logo-home"  alt=""/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            {/* <NavLink to="/pricing" className="nav-link text-lgt pe-4">Pricing</NavLink>
          <NavLink to="/login" className="nav-link text-lgt pe-4">Login</NavLink> */}

          {/* <NavLink href="#home" className="nav-link text-lgt pe-4">Home</NavLink> */}
          <Link to="home" className="nav-link text-lgt pe-4 cursor-pointer" spy={true} smooth={true} offset={-100} duration={100}>Home</Link>
            <Link to="about" className="nav-link text-lgt pe-4 cursor-pointer" spy={true} smooth={true} offset={-100} duration={100}>About</Link>
            <Link to="experience" className="nav-link text-lgt pe-4 cursor-pointer" spy={true} smooth={true} offset={-100} duration={100}>Experience</Link>
            <Link to="expertise" className="nav-link text-lgt pe-4 cursor-pointer" spy={true} smooth={true} offset={-100} duration={100}>Expertise</Link>
            <Link to="certificate" className="nav-link text-lgt pe-4 cursor-pointer" spy={true} smooth={true} offset={-100} duration={100}>Certifications</Link>
            <Link to="contact" className="nav-link text-lgt pe-4 cursor-pointer" spy={true} smooth={true} offset={-100} duration={100}>Contact</Link>
              {/* <Button variant="primary">
                Download CV <i class="uil uil-import"></i>
              </Button> */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
    </>
  )
}

export default NavbarComponent
