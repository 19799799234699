const Certificationapi = [{
    id: 1,
    images: "certificate/cda.png"
},

{
    id: 2,
    images: "certificate/g-suit.png"
},

{
    id: 3,
    images: "certificate/vm.png"
},
{
    id: 4,
    images: "certificate/digital.png"
},
{
    id: 5,
    images: "certificate/tco.png"
},
{
    id: 3,
    images: "certificate/architected.png"
},
];

export default Certificationapi;