import React, { useState }  from 'react'
import Certificationapi from './Certificationapi'
import Fade from 'react-reveal/Fade';

const Certifications = () => {
const[items, setItems] = useState(Certificationapi);



  return (
    <>
     <div className="certification sec bg-lgt" id="certificate">
        <div className="container text-center">
        <Fade bottom>
        <h2 className="section_title title-color pb-4 text-center">Certifications</h2>
        <div className='row justify-content-center pb-3'>
<div className="col-md-4">
<a href="https://www.credly.com/badges/e403d736-6fb9-49e9-a1c8-70d1af1eaac5"><img src="certificate/terraform-associate.png" alt="certificate" /></a>
            </div>
        </div>
        </Fade>


        <div className="row">
            {
                items.map((elem) => {
                    const { id, images } =elem;
                    return(
                      <Fade bottom>
                        <div className="col-md-4">
                        <img src={images} alt="certificate" className="mb-4 shadow-sm"/>
                                    </div>
                                    </Fade>
                    )
                })
            }


            {/* <div className="col-md-4">
<a href="https://www.credly.com/badges/e403d736-6fb9-49e9-a1c8-70d1af1eaac5"><img src="certificate/terraform-associate.png" alt="certificate" /></a>
            </div>
            
            <div className="col-md-4">
<img src="certificate/cda.png" alt="certificate" />
            </div>

            <div className="col-md-4">
<img src="certificate/g-suit.png" alt="certificate" />
            </div>

            <div className="col-md-4">
<img src="certificate/vm.png" alt="certificate" />
            </div>

            <div className="col-md-4">
<img src="certificate/digital.png" alt="certificate" />
            </div>

            <div className="col-md-4">
<img src="certificate/tco.png" alt="certificate" />
            </div> */}
</div>
        </div>
        </div> 
    </>
  )
}

export default Certifications
