// import logo from './logo.svg';
import React from 'react';
import './Styles/style.scss';
import './Styles/home.scss';
import './Styles/about.scss';
import './Styles/services.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import Header from './components/Header';
// import Home from './components/home/Home';
// import About from './components/about/About';
// import Services from './components/services/Services';
// import GalleryReact from './components/TabMenu/GalleryReact';
// import Certifications from './components/certification/Certifications';
// import Contact from './components/Contact/Contact';
// import Footer from './components/footer/Footer';

// import { Link } from "react-router-dom";
import Routers from './routers';

const App = () => {
  return (
    <>
    <Routers/>
    {/* <Header/>
     <main className='main'>
<Home/>
<About/>
<Services/>
<GalleryReact/>
<Certifications/>
<Contact/>
<Footer/>
    </main> */}
      </>
    
  )
}

export default App;
