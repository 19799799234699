// import React from 'react'

const portfolioItems = [{
        id: 1,
        category: "databases",
        images: "assets/elastic.png"
    },
    {
        id: 2,
        category: "devops",
        images: "assets/zen.png"
    },
    {
        id: 3,
        category: "infrastructure",
        images: "assets/Vmware-1.png"
    },
    {
        id: 4,
        category: "programming",
        images: "assets/python.png"
    },
    {
        id: 5,
        category: "server",
        images: "assets/zoho.png"
    },

    {
        id: 6,
        category: "server",
        images: "assets/mail (1).png"
    },
    {
        id: 7,
        category: "devops",
        images: "assets/teraform.png"
    },

    {
        id: 8,
        category: "databases",
        images: "assets/Sql.png"
    },


    {
        id: 9,
        category: "databases",
        images: "assets/Dynamo-db.png"
    },

    {
        id: 10,
        category: "devops",
        images: "assets/new-relic (1).png"
    },

    {
        id: 11,
        category: "devops",
        images: "assets/kuber (1).png"
    },
    {
        id: 12,
        category: "devops",
        images: "assets/helm-new (1).png"
    },
    {
        id: 13,
        category: "devops",
        images: "assets/haproxy (1).png"
    },
    {
        id: 14,
        category: "devops",
        images: "assets/ansible-new (1).png"
    },
    {
        id: 15,
        category: "devops",
        images: "assets/docker.png"
    },


    {
        id: 16,
        category: "infrastructure",
        images: "assets/sophos (1).png"
    },

    {
        id: 17,
        category: "infrastructure",
        images: "assets/office-365 (1).png"
    },

    {
        id: 18,
        category: "infrastructure",
        images: "assets/nagios (1).png"
    },

    {
        id: 19,
        category: "infrastructure",
        images: "assets/gsuit (1).png"
    },

    {
        id: 20,
        category: "infrastructure",
        images: "assets/google-cloud (1).png"
    },

    {
        id: 21,
        category: "infrastructure",
        images: "assets/aws (1).png"
    },

    {
        id: 22,
        category: "programming",
        images: "assets/js-new.png"
    },

    {
        id: 6,
        category: "server",
        images: "assets/nginx (1).png"
    },

    {
        id: 6,
        category: "server",
        images: "assets/Linux.png"
    },

    {
        id: 6,
        category: "server",
        images: "assets/cpanel.png"
    },

    {
        id: 6,
        category: "server",
        images: "assets/apache.png"
    },

];

export default portfolioItems