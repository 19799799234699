import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../pages/main";

const Routers = () => {
  return (
    <BrowserRouter>
    <Routes>
        <Route exact path="/" element={<Main/>} />
    </Routes>
    </BrowserRouter>
  )
}

export default Routers
