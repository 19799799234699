import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';


const PortfolioItem = ({ image }) => (

  <div className="col-md-2 hover-shadow">
<Fade bottom>
  <img src={image}/>
  </Fade>
</div>
//   <div className="portfolio-ite">
//     <div className='row'>
//       <div className='col-md-2'>
//     <img src={image}/>
//  </div>
//  </div>
//   </div>
);


export default PortfolioItem;
