import React from 'react';
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Modal1 from './modal1';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';

const Services = () => {
  const [modal, setmodal] = useState(false);
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [modal3, setmodal3] = useState(false);
  return (
    <>
     <section className="services sec bg-lgt" id="experience">
    <div className="container">
    <h2 className="section_title title-color pb-4 text-center">Work Experience</h2>
      {/* <span className="section_suibtitle"></span> */}



<div className="row services_container">

<Fade bottom>
<div className="col-md-3 services_content text-center">
    {/* <i className="uil uil-web-grid services_icon"></i> */}
    <img src="logo/hle.png" alt="" className="w-50 pb-3" />
    <h4 className="services_title text-lgt">DevOps Engineer,</h4>
    <p className="text-lgt pb-2">Oct 2021 —  Present</p>

  <span className="services_button" onClick={() => setmodal(true)}>View More<i className="uil uil-arrow-right services_button-icon"></i></span>
  <Modal size="lg" isOpen={modal} toggle={() => setmodal(!modal)}>

    <ModalHeader toggle={() => setmodal(!modal)} >HLE Nepal</ModalHeader>
    <ModalBody className="services-mod-content">
    {/* <p>DevOps Engineer(Oct 2021 —  Present)</p> */}
<h4 className="services-mod-title pb-2">DevOps Engineer(Oct 2021 —  Present)</h4>
    
<ul className="services-mod">
  <li className="services_modal-service d-flex">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Writing Infrastructure as Code (IaC) using Industry standard tools and services like Terraform/ terragrunt,
Cloudformation, SAM template , Serverless template, python and bash.</p>
  </li>

  <li className="services_modal-service d-flex pt-2">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Implementing QA strategy on Pipeline like Unit testing, regression testing.</p>
  </li>

  <li className="services_modal-service d-flex pt-2">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Infrastructure, build and deployment automation along with setting up logging, monitoring and alerting
frameworks.</p>
  </li>

  <li className="services_modal-service d-flex pt-2">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Extensively worked on AWS serverless framwork like AWS Lamdba (layers, provisioned concurrency, step
function, AWS Cognito, AWS APIGetway, SQS, SNS, DLQ, AWS Route 53 and ACM etc.</p>
  </li>

  <li className="services_modal-service d-flex pt-2">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Implementing GitOps, SOPS and AWS codebuild for CICD.</p>
  </li>


  <li className="services_modal-service d-flex pt-2">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Designed and implemented an AWS solution that increased performance by 40%.</p>
  </li>


  <li className="services_modal-service d-flex pt-2">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Reduced costs by 30% by designing and implementing an AWS solution.
</p>
  </li>
</ul>
    </ModalBody>
  </Modal>
</div>


<div className="col-md-3 services_content text-center">
    <img src="logo/assabet.png" alt="" className="w-50 pb-3" />
    <h4 className="services_title text-lgt">DevSecOps Consultant and Team Lead,</h4>
    <p className="text-lgt pb-2">Oct 2021 — Sep 2022</p>

  <span className="services_button" onClick={() => setmodal1(true)}>View More<i className="uil uil-arrow-right services_button-icon"></i></span>
  <Modal size="lg" isOpen={modal1} toggle={() => setmodal1(!modal1)}>

<ModalHeader toggle={() => setmodal1(!modal1)} >Assabet Technologies</ModalHeader>
<ModalBody className="services-mod-content">
{/* <p>AWS Solution Architect and DevOps Engineer(Dec 2019 — July 2021)</p> */}
<h4 className="services-mod-title pb-2">DevSecOps Consultant and Team Lead(Oct 2021 — Sep 2022)</h4>

<ul className="services-mod">
<li className="services_modal-service d-flex">
<i className="uil uil-check-circle services_modal-icon"></i>
<p className="services-mod-info mx-2">Building/Maintaining Docker container clusters managed by AWS EKS.</p>
</li>

<li className="services_modal-service d-flex pt-2">
<i className="uil uil-check-circle services_modal-icon"></i>
<p className="services-mod-info mx-2">Worked with the tools like Jenkins, Qualys</p>
</li>

<li className="services_modal-service d-flex pt-2">
<i className="uil uil-check-circle services_modal-icon"></i>
<p className="services-mod-info mx-2">Writing Infrastructure as code like Terraform and Terragrunt.</p>
</li>
</ul>
</ModalBody>
</Modal>
</div>

<div className="col-md-3 services_content text-center ">
  <img src="logo/gen.png" alt="" className="w-50 pb-3"/>
    <h4 className="services_title text-lgt">AWS Solution Architect and DevOps Engineer</h4>
    <p className="text-lgt pb-2">Dec 2019 — July 2021</p>
  <span className="services_button" onClick={() => setmodal2(true)}>View More<i className="uil uil-arrow-right services_button-icon"></i></span>
  <Modal size="lg" isOpen={modal2} toggle={() => setmodal2(!modal2)}>

    <ModalHeader toggle={() => setmodal2(!modal2)} >Genese Solution</ModalHeader>
    <ModalBody className="services-mod-content">
    {/* <p>AWS Solution Architect and DevOps Engineer(Dec 2019 — July 2021)</p> */}
<h4 className="services-mod-title pb-2">AWS Solution Architect and DevOps Engineer(Dec 2019 — July 2021)</h4>
    
<ul className="services-mod">
  <li className="services_modal-service d-flex">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Design and implement cloud solutions which are secure, scalable, resilient, monitored, high availability , auditable and cost optimized.</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Infrastructure, build and deployment automation along with setting up logging, monitoring and alerting
frameworks</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Building/Maintaining Docker container clusters managed by Kubernetes Linux, Bash, GIT, Docker, on GCP (Google Cloud Platform), AWS (EKS and EKS fargate) and Azure(AKS). Utilized Kubernetes and Docker for the runtime environment of the CI/CD system to build, test deploy.</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Writing Infrastructure as Code (IaC) using Industry standard tools and services like Terraform.</p>
  </li>


  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Provide systems support including responding to monitoring alerts.</p>
  </li>


  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Implement and stay abreast of Cloud and DevOps industry best practices and tooling.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Experience with container-based deployments using Docker, working with Docker images, Docker Hub and Jfrog and Kubernetes.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Managed Docker orchestration and Docker containerization using Kubernetes.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Used Kubernetes to orchestrate the deployment, scaling and management of Docker Containers.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Experience worked with Version Control Systems GIT, bitbucket.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Implementing a Continuous Delivery framework using Jenkins/Cloudbeesand AWS code deploy.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Experience on Amazon Comprehend, Amazon SageMaker, Amazon Textract, Amazon Athena, Amazon QuickSight and AWS Glue
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Experience on AWS Serverless framework (S3, Lambda, Cloudfront, D ynamodb, API Getway, Cognito etc.)
</p>
  </li>


</ul>
    </ModalBody>
  </Modal>
</div>


<div className="col-md-3 services_content text-center">
  <img src="logo/gen.png" alt="" className="w-50 pb-3"/>
    <h4 className="services_title text-lgt">System Administrator Team Lead</h4>
    <p className="text-lgt pb-2">July 2017 — Dec 2019</p>
  <span className="services_button" onClick={() => setmodal3(true)}>View More<i className="uil uil-arrow-right services_button-icon"></i></span>
  <Modal size="lg" isOpen={modal3} toggle={() => setmodal3(!modal3)}>

    <ModalHeader toggle={() => setmodal3(!modal3)} >Genese Solution</ModalHeader>
    <ModalBody className="services-mod-content">
    {/* <p>AWS Solution Architect and DevOps Engineer(Dec 2019 — July 2021)</p> */}
<h4 className="services-mod-title">System Administrator Team Lead(July 2017 — Dec 2019)</h4>
<ul className="services-mod">
  <li className="services_modal-service d-flex">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Expertise in AWS Cloud</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Expertise in Linux Administration Installation, Configuration, Tuning and Upgrades of Linux.</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Experience in VMware vSphere, VCenter and VMotion.</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Domain Management, mail server management (Gsuite, office 365, zoho) and Network management.</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">AWS support and Linux Support to 300+ client</p>
  </li>


  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">AWS support and Linux Support to 300+ client</p>
  </li>


  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">AWS support and Linux Support to 300+ client</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Extensively worked on cPanel / WHM installation & support (worked as reseller support ) and Mail server issues like spamming.
</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">SSL certification management</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Very Strong understanding of Linux Administration and hosting (dedicated, share hosting)</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Responsible for day to day operations and administration of the IT infrastructure.</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Led team of System Administrators in daily tasks</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Monitored team member performance and provided feedback</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Assigned tasks to team members and monitored progress</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Answered team member questions and provided guidance</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Investigated and resolved team member issues</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Investigated and resolved team member issues</p>
  </li>

  <li className="services_modal-service d-flex pt-1">
    <i className="uil uil-check-circle services_modal-icon"></i>
    <p className="services-mod-info mx-2">Escalated issues to higher level when necessary</p>
  </li>
</ul>
    </ModalBody>
  </Modal>
</div>
</Fade>






</div>

</div>
      </section> 
    </>
  )
}

export default Services
