import React, { useState } from 'react'
import Expertiseapi from './expertiseapi'
// import PortfolioItem from './PortfolioItem';
import portfolioItems from './portfolioItems';
import PortfolioItem from './PortfolioItem';
import Fade from 'react-reveal/Fade';

const GalleryReact = () => {
  const[items, setItems] = useState(Expertiseapi);
const filterItem = (categItem) => {
    const updatedItems = Expertiseapi.filter((curElem) =>{
return curElem.category === categItem;
    });

    setItems(updatedItems);
}


const [activeFilter, setActiveFilter] = useState('all');
  const [filteredPortfolioItems, setFilteredPortfolioItems] = useState(portfolioItems);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    if (filter === 'all') {
      setFilteredPortfolioItems(portfolioItems);
    } else {
      const filteredItems = portfolioItems.filter((item) => item.category === filter);
      setFilteredPortfolioItems(filteredItems);
    }
  };

  const filterOptions = ['all', 'databases', 'devops', 'infrastructure', 'programming', 'server'];

  return (
    <>
      <section className="services sec" id="expertise">
      <h2 className="section_title title-color pb-4 text-center">Expertise</h2>

{/* New Tabs */}
<div className="portfolio menu-tabs container">

      <div className="filter-buttons menu-tab tabs d-flex justify-content-center">
        {filterOptions.map((filter) => (
          <filter
            key={filter}
            onClick={() => handleFilterClick(filter)}
            className={activeFilter === filter ? 'active' : ''}
          >
            {filter}
          </filter>
        ))}
      </div>
     
      <div className="menu-item mt-4 text-center">   
{/* <div className="col-img"> */}
<div className="row">
        {filteredPortfolioItems.map((item) => (
          <PortfolioItem
            image={item.images}
          />
        ))}

</div>
      </div>
     
    </div>



{/* my main item */}

{/* <div className="menu-item mt-5 container">
    <div className="row">

{
    items.map((elem) => {
const { images } = elem;

return(
<div className="col-md-2">
            <img src={images} alt="Databases" className="img-fl" />
           
        </div>
)
    })
}
    </div>
</div> */}

</section>
    </>
  )
}



export default GalleryReact
