import React from 'react';
import CV from "../../assets/sushan.pdf";
import AboutImg from "../../assets/profile.jpg";
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';

// import Info from './Info';

const About = () => {
  return (
    <>
    <div>
    
    <section className= "about sec" id="about" >
<div className="about_container container align-item-center text-center">
<Fade bottom>
<h2 className="section_title title-color pb-4">About</h2>
<div className="about_im">
  <img src={AboutImg} alt="" className="about_img"/>
  </div>
  </Fade>
  
  <Fade bottom>
  <div className="about-social pt-4 pb-3">
<a href="https://www.linkedin.com/in/sushan-tiwari-7842a316b/" className="home_social-icon px-3" target="_blank"><img src="social/linkedin.svg" className="social-icon" alt="" /></a>
<a href="#" className="home_social-icon" target="_blank"><img src="social/github.svg" className="social-icon" alt="" /></a>
        <a href="https://www.instagram.com/aghori_shivansh/" className="home_social-icon px-3" target="_blank"><img src="social/insta1.svg" className="social-icon" alt="" /></a>
        <a href="https://www.facebook.com/sushan.tiwari.50" className="home_social-icon" target="_blank"><img src="social/facebook.svg" className="social-icon" alt="" /></a>
</div>


   <h3 className="section_title text-lgt">Sushan Tiwari</h3>
{/* <span className="section_subtitle"> My Introduction </span> */}
  <div className="about_data text-center w-80 margin-auto">
    <p className="about_description text-lgt">Always been a believer in the power of technology. It’s always fascinating to watch how small tech can change the whole world. From a very early age, I have been a keen learner of various technologies. I started off as a System Administrator and now I am AWS Solution Architect and DevOps Engineer. Hard work and dedication forever pays off.</p>
    {/* <Info /> */}
{/* <a download="" href="/" className="btn btn-primary mt-3">Learn More</a> */}
<a download="" href={CV} className="btn btn-primary mt-4">Download CV <i class="uil uil-arrow-to-bottom"></i></a>
  </div>
  </Fade>
  



</div>
    </section>
    </div>
    </>
  )
}

export default About