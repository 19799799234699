import React from 'react';
import LayoutComponent from '../shares/LayoutComponent';
import FooterComponent from '../shares/FooterComponent';
import Home from '../components/home/Home';
import About from '../components/about/About';
import Services from '../components/services/Services';
import GalleryReact from '../components/TabMenu/GalleryReact';
import Certifications from '../components/certification/Certifications';
import Contact from '../components/Contact/Contact';

const Main = () => {
  return (
    <LayoutComponent>
        <Home/>
        <About/>
        <Services id="experience"/>
        <GalleryReact/>
        <Certifications/>
        <Contact/>
        <FooterComponent/>
    </LayoutComponent>
  )
}

export default Main
