 import React from 'react';
 import Social from './Social';
 import Data from './Data';
 import Reveal from 'react-reveal/Reveal';
 import Fade from 'react-reveal/Fade';


const Home = () => {
  return (
    <>
    <section className="home section bg-color" id="home">
   
        <div className="home_container container">
            <div className="row home_content">
                {/* <div className="col-md-1"><Social/></div> */}
                <div className="col-md-8"> <Data/></div>
                
                <Fade top>
                <div className="col-md-4 bnr-mob">
                <div className="home_img"></div>
                </div>
                </Fade>

            </div>

        </div>
       
    </section>
    </>
  )
}

export default Home
