const Expertiseapi = [{
        id: 1,
        category: "databases",
        images: "assets/elastic.png"
    },


    {
        id: 2,
        category: "databases",
        images: "assets/Dynamo-db.png"
    },

    {
        id: 3,
        category: "databases",
        images: "assets/elastic.png"
    },


    {
        id: 2,
        category: "devops",
        images: "assets/zen.png"
    },
    {
        id: 3,
        category: "infrastructure",
        images: "assets/Vmware-1.png"
    },
    {
        id: 4,
        category: "programming",
        images: "assets/python.png"
    },
    {
        id: 5,
        category: "server",
        images: "assets/zoho.png"
    },

    {
        id: 5,
        category: "server",
        images: "assets/zoho.png"
    },
    {
        id: 5,
        category: "devops",
        images: "assets/zoho.png"
    },
];

export default Expertiseapi;