import React from 'react'
// import Title from './Title';
// import Typed from "react-typed"
import Typewriter from "typewriter-effect";
import Fade from 'react-reveal/Fade';

const Data = () => {
  return (
    <>
      <div className="home_data text-white">
        {/* <h1 className="home_title text-white">DevSecOps Engineer</h1> */}

       <h1 className="text-white typewrite-mob"> <Typewriter
        options={{
          autoStart: true,
          loop: true,
          delay: 40,
          strings: [
            "DevSecOps Engineer",
            "AWS Serverless | Python",
            "Terraform | K8s",
            "IT Engineer"
          ],
        }}
        />
        </h1>
      

        {/* <h3 className="home_subtitle">from Nepal</h3> */}
        <p className="home-description text-white pb-4">AWS Certified Developer Associate with 5+ years of hands-on experience on developing, supporting, automating and optimizing mission critical application deployment in AWS, GCP, leveraging configuration management, CI/CD and DevOps Process.</p>
<a href="#contact" className="btn btn-primary">Ping Me <i class="uil uil-arrow-right"></i></a>
      </div>
    </>
  )
}

export default Data
