import React from 'react'

const FooterComponent = () => {
  return (
    <div>
      <footer className="py-4 bg-lgt">  
        <div className="container text-center">
<div className="about-social">
<a href="https://www.linkedin.com/in/sushan-tiwari-7842a316b/" className="home_social-icon px-3" target="_blank"><img src="social/linkedin.svg" className="social-icon" alt="" /></a>
<a href="#" className="home_social-icon" target="_blank"><img src="social/github.svg" className="social-icon" alt="" /></a>
        <a href="https://www.instagram.com/aghori_shivansh/" className="home_social-icon px-3" target="_blank"><img src="social/insta1.svg" className="social-icon" alt="" /></a>
        <a href="https://www.facebook.com/sushan.tiwari.50" className="home_social-icon" target="_blank"><img src="social/facebook.svg" className="social-icon" alt="" /></a>
</div>
{/* <p class="copyright pt-2 text-lgt">
Copyright © 2023 Sushan Tiwari. All Rights Reserved.</p> */}

<p class="copyright pt-2 text-lgt">Copyright © {new Date().getFullYear()} Sushan Tiwari. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  )
}

export default FooterComponent
