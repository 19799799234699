import React, {useEffect, useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
// import { toast } from "react-toastify";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fade from 'react-reveal/Fade';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_m803nul', 'template_jh6z4iq', form.current, 'PUqxnHcfRPKa3Nb1M')
      .then(
        (result) => {
          toast.success("Email Sent Successful", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
    e.target.reset();
  };
  

  return (
    <>
      <div className="contact-section sec" id="contact">
        <div className="container">
        <Fade bottom>
        <h2 className="section_title title-color pb-4 text-center">Get In Touch</h2>
        <div className="contact-info pb-3 text-center">
            <div className="row">
            <div className="col-md-4 info-block pb-2">
               <div className="bx-icon">
                <i class='bx bxs-phone bx-icon'></i>
                </div>
                <a href="tel:9861686186" className="text-lgt">+977-9861686186</a>
                </div>

                <div className="col-md-4 info-block pb-3">
               <div className="bx-icon">
               <i class='bx bxs-envelope bx-icon'></i>
                </div>
                <a href="mailto:sushan@sushantiwari.com.np" className="text-lgt">sushan@sushantiwari.com.np</a>
                </div>

                <div className="col-md-4 info-block pb-3">
               <div className="bx-icon">
               <i class='bx bxs-map bx-icon' ></i>
                </div>
                <a href="/" className="text-lgt">Kathmandu, Jorpati</a>
                </div>
            </div>
        </div>
        </Fade>

        <Fade bottom>
        <div className="contact-form w-80 margin-auto">
        <ToastContainer />
        <form ref={form} onSubmit={sendEmail}>
       {/* <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" /> 
      <input type="submit" value="Send" /> */}

      <div className="row form-row">
        <span className="py-4 text-center title-color">Feel free to drop me a Message! I would get back to you within 24 hours.</span>

    <div className="form-group col-md-6 pb-2">
      <label for="inputEmail4" className="pb-2">Name</label>
      <input type="text" name="user_name" class="form-control" required/>
    </div>
    <div className="form-group col-md-6 pb-2">
      <label for="inputEmail4"  className="pb-2">Email</label>
      <input type="email" name="user_email" class="form-control" required/>
    </div>
  </div>

  <div className="form-group">
    <label for="inputAddress" className= "pt-2 pb-2">Message</label>
    <textarea id="message" name="message" class="form-control" rows="5" required></textarea>
  </div>

  <button type="submit" value="Send" class="btn btn-primary w-100 mt-4">Send <i class="uil uil-message"></i></button>
    </form>
        </div>
        </Fade>
        </div>
      </div>
    </>
  )
}

export default Contact
